import { createApi } from '@reduxjs/toolkit/query/react';

import type { ResponseType } from 'src/api/http';
import baseQuery from './baseQuery';
import type { IUser, UserStatusENUM } from 'src/types';
import type { GetParamsType } from '../http/http.types';

const path = 'users';

export const usersQuery = createApi({
  reducerPath: 'usersQuery',
  baseQuery: baseQuery<IUser[]>(),
  endpoints: (builder) => ({
    getUsers: builder.query<ResponseType<IUser[]>, GetParamsType & {
      status?: UserStatusENUM;
    }>({
      query: (params) => ({
        url: path,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
} = usersQuery;
