import type { TFunction } from 'react-i18next';
// Relace all date-fns usage
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import addYears from 'date-fns/addYears';
import addMonths from 'date-fns/addMonths';
import localeRu from 'date-fns/locale/ru';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import Quill, { type Delta } from 'quill';

import store, { resetStore } from 'src/store';
import storage from './storage';
import config from 'src/config';
import { ROUTES } from './constants';
import socketConnection from 'src/api/ws/socket';

const safeJsonParse = <R = unknown>(string: string, fallbackValue?: R | null): R | null => {
  try {
    const parsed = JSON.parse(string);

    return parsed as R;
  } catch {
    return fallbackValue || null;
  }
};

const sleep = (timeout: number) => new Promise((res) => { setTimeout(res, timeout); });

const setPhoneFormat = (phoneString: string) => {
  return phoneString.replace(/^(\D{1})(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/, '$1$2 ($3) $4-$5-$6');
};

const pretifyPhone = (phone: string) => {
  return phone.replace(/^(\+7)(\d{3})(\d{3})(\d{2})(\d{2})$/, '$1 $2 $3-$4-$5');
};

export const getDistanceToNow = (date: Date, t: TFunction<'hrmEmployees'>) => {
  const getNumberFromString = (str: string) => {
    return Number(str.match(/[0-9]*/g)?.[0]);
  };

  const yearString = formatDistanceToNowStrict(
    date,
    { unit: 'year', locale: localeRu, roundingMethod: 'floor' },
  );
  const yearsCount = getNumberFromString(yearString);

  const montsDate = addYears(date, yearsCount);
  const monthsString = formatDistanceToNowStrict(
    montsDate,
    { unit: 'month', locale: localeRu, roundingMethod: 'floor' },
  );
  const monthsCount = getNumberFromString(monthsString);

  const daysDate = addMonths(montsDate, monthsCount);
  const daysString = formatDistanceToNowStrict(
    daysDate,
    { unit: 'day', locale: localeRu, roundingMethod: 'floor' },
  );

  return `${yearString} ${monthsString} ${daysString}`.replace(/(?:^| )0 [^0-9 ]+/gi, '') || t('userProfile.info.zeroDays');
};

const logOut = () => {
  store.dispatch(resetStore());
  storage.authToken.remove();
  storage.refreshToken.remove();
  storage.chatDrafts.remove();
  storage.selectedWorkspaces.remove();
  socketConnection.disconnect();
  window.location.replace(`${config.protocol}${config.domain}`);
};

const getSubdomain = () => {
  return window.location.host.split('.')[0];
};

const replaceSubdomain = (subdomain: string, pathName?: string) => {
  const currentPath = `${config.protocol}${subdomain}.${config.domain}`;
  if (pathName) {
    window.location.replace(`${currentPath}${pathName}`);
    return;
  }
  window.location.replace(`${currentPath}${ROUTES.employees.path}`);
};

const capitalizeFirstLetter = (str: string) => {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

const removeFromArray = <T_ArrayItem>(
  array: T_ArrayItem[],
  itemOrCallback: T_ArrayItem | ((item: T_ArrayItem) => (boolean | unknown)),
) => {
  const checkFunction = (item: T_ArrayItem) => {
    if (typeof itemOrCallback === 'function') {
      return (itemOrCallback as (item: T_ArrayItem) => (boolean | unknown))(item);
    }

    return item === itemOrCallback;
  };

  const itemIndex = array.findIndex(checkFunction);

  const formattedArray = [...array];

  if (itemIndex === -1) {
    return formattedArray;
  }

  formattedArray.splice(itemIndex, 1);

  return formattedArray;
};

const writeToClipboard = <T_ShowToast extends boolean>(
  text?: string,
  options?: {
    showToast?: T_ShowToast;
    errorMessage?: string;
  } & (T_ShowToast extends true ? { successMessage: string } : Record<never, never>),
) => {
  if (!text) {
    return;
  }

  const isSuccess = copy(text);

  if (!options?.showToast) {
    return;
  }

  if (isSuccess) {
    toast.success((options as Record<string, string>).successMessage);
  } else {
    toast.error(options?.errorMessage);
  }
};

const getAbsoluteUrl = (args: { path?: string; withoutProtocol?: boolean }) => {
  const url = `${window.location.host}${args.path || ''}`;
  if (args.withoutProtocol) {
    return url;
  }
  return `${config.protocol}${url}`;
};

const arrayToObject = <T_ArrayItem extends object>(
  array: T_ArrayItem[],
  key: keyof T_ArrayItem,
) => {
  const object = array.reduce<Record<string, T_ArrayItem>>((acc, item) => {
    acc[item[key] as string] = item;

    return acc;
  }, {});

  return object;
};

let quill: Quill;
export const quillDeltaToHtmlString = (delta: Delta | string) => {
  if (!delta || typeof delta === 'string') {
    return '';
  }

  if (!quill) {
    const quillContainer = document.createElement('div');
    quillContainer.style.display = 'none';
    quillContainer.id = 'quill-delta-reader';
    document.head.append(quillContainer);

    quill = new Quill(`#${quillContainer.id}`, {});
  }

  quill.setContents(delta);
  const htmlString = quill.root.innerHTML;
  return htmlString;
};

export default {
  setPhoneFormat,
  safeJsonParse,
  sleep,
  logOut,
  getDistanceToNow,
  getSubdomain,
  replaceSubdomain,
  pretifyPhone,
  capitalizeFirstLetter,
  removeFromArray,
  writeToClipboard,
  getAbsoluteUrl,
  arrayToObject,
};
