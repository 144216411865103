import CustomEventCreator from 'src/utils/CustomEventCreator';

const MESSAGE_ID_TEMPLATE = '{{messageId}}';
const MESSAGE_RENDERED_EVENT_NAME = `CHAT_SCROLL__MESSAGE_${MESSAGE_ID_TEMPLATE}_RENDERED`;

class ChatScrollController {
  private channelId: number | null = null;
  private dataPropName = 'data-chat-message-id';
  private newMessagesBannerId = 'new-messages-banner';
  private wasScrolledToFirstUnread = false;

  private resetChannelData() {
    this.wasScrolledToFirstUnread = false;
  }

  setChannelId(channelId: number) {
    if (this.channelId === channelId) {
      return;
    }
    this.channelId = channelId;
    this.resetChannelData();
  }

  private getMessageElement(messageId: number) {
    return document.querySelector(`[${this.dataPropName}="${messageId}"]`);
  }

  getMessageDataProps(messageId: number) {
    return ({ [this.dataPropName]: messageId });
  }

  getNewMEssagesBannerProps() {
    return {
      id: this.newMessagesBannerId,
    };
  }

  private messageRenderedEvent = new CustomEventCreator<{ messageId: number }>();
  private newMessagesBannerEvent = new CustomEventCreator({ eventName: 'NEW_MESSAGES_BANNER_RENDER' });

  // eslint-disable-next-line class-methods-use-this
  private getMessageRenderedEventName(messageId: number) {
    return MESSAGE_RENDERED_EVENT_NAME.replace(MESSAGE_ID_TEMPLATE, messageId.toString());
  }

  // eslint-disable-next-line class-methods-use-this
  private async privateScrollTo(params: {
    getElement: () => Element | null;
    eventController: CustomEventCreator;
    getEventName?: () => string;
    smooth?: boolean;
    timeoutValue?: number;
  }) {
    let element = params.getElement();

    if (!element) {
      element = await new Promise((res, rej) => {
        const unsubscribe = params.eventController.subscribe(
          () => {
            unsubscribe();
            const element = params.getElement();

            res(element);
            clearTimeout(timeoutId);
          },
          { eventName: params.getEventName?.() },
        );

        const timeoutId = setTimeout(() => {
          rej(new Error('Scroll is failed (timeout)'));
          unsubscribe();
        }, params.timeoutValue || 5_000);
      });
    }

    if (!element) {
      throw new Error('Element not found');
    }

    element.scrollIntoView({
      behavior: params.smooth ? 'smooth' : 'auto',
    });
  }

  async scrollToMessage(data: {
    messageId: number;
    smooth?: boolean;
  }) {
    if (!this.channelId) {
      throw new Error('No channel selected');
    }

    this.privateScrollTo({
      getElement: () => this.getMessageElement(data.messageId),
      eventController: this.messageRenderedEvent,
      getEventName: () => this.getMessageRenderedEventName(data.messageId),
      smooth: data.smooth,
    });
  }

  emitRenderedMessage(data: {
    messageId: number;
  }) {
    this.messageRenderedEvent.dispatch(data, {
      eventName: this.getMessageRenderedEventName(data.messageId),
    });
  }

  emitRenderedNewMessagesBanner = () => {
    this.newMessagesBannerEvent.dispatch({}, {});
  };

  scrollToFirstUnread() {
    if (this.wasScrolledToFirstUnread) {
      return;
    }

    this.wasScrolledToFirstUnread = true;

    this.privateScrollTo({
      getElement: () => document.getElementById(this.newMessagesBannerId),
      eventController: this.newMessagesBannerEvent,
      smooth: false,
    });
  }
}

export default new ChatScrollController();
