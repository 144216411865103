const PER_PAGE = 30;
const SIDE_MESSAGES_COUNT = 15;
const RESERVE_COUNT = 3;
const MAX_FILE_SIZE = 1024 * 1024 * 1024;
const SUPPORTED_IMAGE_TYPE = ['jpeg', 'png', 'webp', 'gif'];

export const DEFAULT_ICON_TEXT = '⚪';

export const MESSAGES_FEED_SCROLL_CONTAINER_CLASSNAME = '__messages-scroll-container__';
export const MESSAGES_LIST_LIMIT = 40;
export const LOAD_MORE_ANCHOR_OFFSET = 5;

export default {
  PER_PAGE,
  SIDE_MESSAGES_COUNT,
  RESERVE_COUNT,
  MAX_FILE_SIZE,
  SUPPORTED_IMAGE_TYPE,
};
