const noEventNameError = new Error('You should specify "eventName" in constructor or method\'s params');
const noElementError = new Error('You should specify "element" in constructor or method\'s params');

type TargetElementType = HTMLElement | Document | Window;

type ActionParamsType = {
  eventName?: string;
  element?: TargetElementType;
};

class CustomEventCreator<T_Data extends object = EmptyObjectType> {
  eventName?: string;
  element?: TargetElementType;

  constructor(params?: {
    eventName?: string;
    /** Default value - `window` */
    element?: TargetElementType;
  }) {
    this.element = params?.element || window;
    this.eventName = params?.eventName;
  }

  private getEventParams(params?: ActionParamsType) {
    const eventName = params?.eventName || this.eventName;
    if (!eventName) {
      throw noEventNameError;
    }

    const element = params?.element || this.element;
    if (!element) {
      throw noElementError;
    }

    return {
      eventName,
      element,
    };
  }

  dispatch(data: T_Data, params?: ActionParamsType) {
    const { eventName, element } = this.getEventParams(params);

    const event = new CustomEvent(eventName, {
      detail: {
        value: JSON.stringify(data),
      },
    });

    element.dispatchEvent(event);
  }

  subscribe(callback: (data: T_Data) => void, params?: ActionParamsType) {
    const { eventName, element } = this.getEventParams(params);

    const handler = ((ev: CustomEvent<{
      event: string;
      value: string;
    }>) => {
      callback(JSON.parse(ev.detail.value) as T_Data);
    }) as (ev: Event) => void;

    element.addEventListener(eventName, handler);

    const unsubscribe = () => {
      element.removeEventListener(eventName, handler);
    };

    return unsubscribe;
  }
}

export default CustomEventCreator;
