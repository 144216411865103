import http, { type ResponseType } from 'src/api/http';
import type { IMessage } from 'src/types';

const edit = (messageId: number, data: {
  messageText: string;
  addUserMentionsIds: number[];
  removeUserMentionsIds: number[];
}) => {
  return http.patch<ResponseType<IMessage>>(`/messages/${messageId}`, data);
};

export default {
  edit,
};
