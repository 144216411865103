import _uniq from 'lodash/uniq';
import _difference from 'lodash/difference';

import type { IMessage } from 'src/types';
import LocalStorageItem from 'src/utils/storage/LocalStorageItem';

export type PendingMessagesType = {
  sending: IMessage[];
  errored: IMessage[];
};

const pendingMessagesStorage = new LocalStorageItem<{
  [userId: string]: {
    [channelId: string]: PendingMessagesType;
  };
}>({
  key: 'chat_pending-messages',
});

export const getPendingMessages = (channelId: number, userId: number): PendingMessagesType => {
  const storedData = pendingMessagesStorage.get();

  const data = storedData?.[userId]?.[channelId] || { sending: [], errored: [] };

  return data;
};

export const setPendingMessages = (channelId: number, userId: number, data: PendingMessagesType) => {
  const storedData = pendingMessagesStorage.get() || {};
  if (!storedData[userId]) {
    storedData[userId] = {};
  }
  storedData[userId][channelId] = data;

  pendingMessagesStorage.set(storedData);
};

const getMentionUsersIds = (value: string) => {
  const tagsAndStringRegex = /(<[^>]+>)/gi;
  const tags = value.split(tagsAndStringRegex).filter((item) => item.startsWith('<span class="mention"'));
  const ids: number[] = [];
  for (const tag of tags) {
    const dataId = tag.split(' ').find((item) => item.startsWith('data-id'));
    if (dataId) {
      ids.push(+dataId.replace(/[^\d]/g, ''));
    }
  }

  return ids;
};

export const getMentionsFromText = (text: string, oldText?: string) => {
  const newMentions = _uniq(getMentionUsersIds(text));

  if (typeof oldText !== 'string') {
    return {
      add: newMentions,
      remove: [],
    };
  }

  const oldMentions = _uniq(getMentionUsersIds(oldText));
  const addUserMentionsIds = _difference(newMentions, oldMentions);
  const removeUserMentionsIds = _difference(oldMentions, newMentions);

  return {
    add: addUserMentionsIds,
    remove: removeUserMentionsIds,
  };
};
