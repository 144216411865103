import React from 'react';
import _random from 'lodash/random';

import { Link } from 'react-router-dom';
import UserPopover from 'src/ui/components/UserPopover';
import UserPopoverContent from 'src/ui/components/UserPopover/UserPopoverContent';
import StyledAvatar from 'src/ui/components/Avatar/Avatar.styles';

import type { IUser } from 'src/types';
import type { IMediaItem } from 'src/types/userTypes';
import type { ICompany } from 'src/types/generalTypes';
import { ROUTES } from 'src/utils/constants';
import useTheme from 'src/utils/hooks/useTheme';
import { useAppDispatch } from 'src/store/store';
import { chatSliceActions } from 'src/ui/pages/Chat/store/Chat.reducer';

const sizeOptions = {
  xxxxs: { width: 16, height: 16, fontSize: '0.6rem' },
  xxxs: { width: 18, height: 18, fontSize: '0.7rem' },
  xsx: { width: 24, height: 24, fontSize: '0.75rem' },
  sx: { width: 30, height: 30, fontSize: '0.9rem' },
  s: { width: 32, height: 32, fontSize: '0.9rem' },
  sm: { width: 36, height: 36, fontSize: '1rem' },
  md: { width: 60, height: 60, fontSize: '1.8rem' },
  lg: { width: 80, height: 80, fontSize: '2.5rem' },
  xlg: { width: 150, height: 150, fontSize: '3rem' },
  usersListCard: { width: 40, height: 40, fontSize: '1.2rem' },
  usersListTable: { width: 28, height: 28, fontSize: '0.9rem' },
  profile: { width: 80, height: 80, fontSize: '2.5rem' },
};

export type AvatarPropsType = {
  user?: IUser;
  company?: ICompany;
  className?: string;
  showTooltip?: boolean;
  withLink?: boolean;
  linkClassName?: string;
  size?: keyof typeof sizeOptions;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  pointerCursor?: boolean;
  usePreventClick?: boolean;
  sourceSize?: 'full' | 'thumbnail' | 'tinyThumbnail';
};

const Avatar: React.FC<AvatarPropsType> = (props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const src = React.useMemo(() => {
    if (!props.user?.avatarMediaItem && !props.company?.logoMediaItem) {
      return undefined;
    }

    const image = (props.user?.avatarMediaItem || props.company?.logoMediaItem) as IMediaItem;
    let src;
    switch (props.sourceSize) {
      case 'tinyThumbnail':
        src = image.tinyThumbLink;
        break;

      case 'full':
        src = image.link;
        break;

      default:
        src = image.thumbLink;
    }

    return src;
  }, [props.user, props.sourceSize, props.company]);

  const userName = props.user?.fullName || props.user?.email || props.company?.name;
  const initials = React.useMemo(() => {
    if (!props.user) {
      if (!props.company?.name) {
        return '';
      }
      return props.company.name[0].toUpperCase();
    }
    const firstName = props.user.firstName?.[0] || '';
    const lastName = props.user.lastName?.[0] || '';

    return `${firstName}${lastName}` || props.user.email?.[0];
  }, [props.user, props.company]);

  const backgroundColor = React.useMemo(() => {
    const backgroundColors = theme.palette.avatarsBackground;
    return backgroundColors[_random(0, backgroundColors.length - 1, false)];
  }, [theme]);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = async (ev) => {
    if (props.usePreventClick) {
      return;
    }
    if (props.onClick) {
      return props.onClick(ev);
    }
    const defaultHandleClick = () => {
      if (props.user) {
        dispatch(chatSliceActions.setIsShowRightSideBar(true));
        dispatch(chatSliceActions.setCurrentViewedUserProfile(props.user));
      }
    };
    return defaultHandleClick();
  };

  const [openedPopover, setOpenedPopover] = React.useState(false);
  const popoverAnchor = React.useRef(null);
  const handlePopoverOpen = () => {
    setOpenedPopover(true);
  };

  const handlePopoverClose = () => {
    setOpenedPopover(false);
  };

  const avatarElementProps = {
    className: props.className,
    alt: userName,
    src,
    background: backgroundColor,
    onClick: handleClick,
    pointerCursor: props.pointerCursor ?? false,
  };

  let avatarElement = (
    <StyledAvatar
      {...avatarElementProps}
      sx={sizeOptions[props.size || 'md']}
      fontSize={sizeOptions[props.size || 'md'].fontSize}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      aria-owns={openedPopover ? `mouse-over-popover__${props.user?.userId}` : undefined}
      aria-haspopup="true"
      ref={popoverAnchor}
    >
      {initials}
    </StyledAvatar>
  );

  const bigAvatar = (
    <StyledAvatar
      {...avatarElementProps}
      sx={sizeOptions.lg}
      fontSize={sizeOptions.lg.fontSize}
    >
      {initials}
    </StyledAvatar>
  );

  if (props.withLink && props.user) {
    avatarElement = (
      <Link
        className={props.linkClassName}
        to={ROUTES.profile.createPath(props.user.userId)}
      >
        {avatarElement}
      </Link>
    );
  }

  if (props.showTooltip ?? false) {
    avatarElement = (
      <div>
        {avatarElement}
        <UserPopover
          open={openedPopover}
          anchorEl={popoverAnchor.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          id={`mouse-over-popover__${props.user?.userId}`}
          sx={{
            pointerEvents: 'none',
          }}
          PaperProps={{
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose,
          }}
        >
          <UserPopoverContent
            user={props.user}
            avatar={bigAvatar}
            handleClick={handleClick}
          />
        </UserPopover>
      </div>
    );
  }

  return avatarElement;
};

export default Avatar;
