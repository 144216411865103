import socket from 'src/api/ws/socket';
import { SocketEventsENUM } from 'src/api/ws/socketEvents';
import type { IMessage } from 'src/types';

const getChannelMesages = (params: {
  channelId: number;
  unreadMessagesCount?: number;
  limit?: number;
  upAnchor?: string | Date;
  downAnchor?: string | Date;
}) => {
  return socket.emit<IMessage[], {
    hasMoreUp?: boolean;
    hasMoreDown?: boolean;
    firstUnreadMessageId?: number | null;
  }>(SocketEventsENUM.chat_getChannelMessages, params);
};

export default {
  getChannelMesages,
};
