import type { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import _uniq from 'lodash/uniq';
import _omit from 'lodash/omit';

import chatThunksV2 from './chatThunksV2';
import { ChatTypesENUM } from 'src/types';
import type { ChannelMetaDataType, IChannel, IMessage, IUser, UserToChannelsType } from 'src/types';
import helpers from 'src/utils/helpers';
import {
  type PendingMessagesType,
  getPendingMessages,
  setPendingMessages,
} from './chatSliceUtils';
import { toast } from 'react-toastify';
import { t } from 'src/utils/getTranslation';

const chatSliceV2 = createSlice({
  name: 'chatPage-v2',
  initialState: () => ({
    channelsObject: {} as Record<string, IChannel>,
    channels: [] as number[],
    dmChannels: [] as number[],
    archivedChannels: [] as number[],
    channelsMeta: {} as Record<string, ChannelMetaDataType>,
    isChannelsLoading: true,
    getMyChannelsError: null as SerializedError | null,

    usersObject: {} as Record<string, IUser>,

    openedThread: null as null | {
      channelId: number;
      parentMessageId: number;
    },

    channelDetailsObject: {} as {
      [channelId: number]: {
        channelId: number;
        usersToChannel: Omit<UserToChannelsType, 'user' | 'channel'>[];
        messages: Array<{
          messageId: number;
          /** Array of message IDs */
          threadMessages: number[];
          threadMessagesObject: Record<string, IMessage>;
        }>;
        pendingMessages: PendingMessagesType;
        channelMessagesObject: Record<string, IMessage>;
        /** Array of message IDs */
        pinnedMessages: number[];

        hasMoreUp: boolean;
        isLoadingUp: boolean;
        hasMoreDown: boolean;
        isLoadingDown: boolean;
        firstUnreadMessageId: number | null;

        openedThreadMessageId: number | null;
      };
    },
  }),
  reducers: {
    handleNewChannel: (store, { payload }: PayloadAction<{
      channel: IChannel;
      meta?: ChannelMetaDataType;
    }>) => {
      const { channel } = payload;
      const { channelId } = channel;

      // Burn it with fire! But later
      if (store.channelDetailsObject[channelId]?.usersToChannel) {
        store.channelDetailsObject[channelId].usersToChannel = payload.channel.userToChannels!.map((userToChannel) => {
          return {
            ...userToChannel,
            userId: userToChannel.user?.userId || userToChannel.userId,
          };
        });
      }

      store.channelsObject[channelId] = channel;
      store.channelsMeta[channelId] = payload.meta || {
        channelId,
        unreadMessagesCount: 0,
        isContainsMention: false,
      };
      if (channel.isArchived) {
        store.archivedChannels.push(channelId);
        store.archivedChannels = _uniq(store.archivedChannels);
      } else if (channel.type === ChatTypesENUM.channel) {
        store.channels.push(channelId);
        store.channels = _uniq(store.channels);
      } else {
        store.dmChannels.push(channelId);
        store.dmChannels = _uniq(store.dmChannels);
      }
    },
    handleNewMessage_old: (store, { payload }: PayloadAction<{
      chatId: number;
      isMyMessage: boolean;
      message: IMessage;
    }>) => {
      if (payload.isMyMessage) {
        return;
      }

      if (!store.channelDetailsObject[payload.chatId].hasMoreDown) {
        store.channelDetailsObject[payload.chatId].channelMessagesObject[payload.message.messageId] = payload.message;
        store.channelDetailsObject[payload.chatId].messages.push({
          messageId: payload.message.messageId,
          threadMessages: [],
          threadMessagesObject: {},
        });
      }

      store.channelsMeta[payload.chatId].unreadMessagesCount += 1;

      new Audio('/assets/sound/icq.mp3').play(); // Just for fun 🙂
    },
    handleNewMention: (store, { payload }: PayloadAction<{ channelId: number }>) => {
      store.channelsMeta[payload.channelId].isContainsMention = true;
    },
    handleChannelUpdate: (
      store,
      { payload }: PayloadAction<{
        channel: { channelId: number } & Partial<IChannel>;
        channelMeta?: ChannelMetaDataType;
      }>,
    ) => {
      const currentValue = store.channelsObject[payload.channel.channelId];

      if (typeof payload.channel.isArchived === 'boolean' && currentValue?.isArchived !== payload.channel.isArchived) {
        if (payload.channel.isArchived) {
          store.archivedChannels.push(payload.channel.channelId);
          store.channels = helpers.removeFromArray(store.channels, payload.channel.channelId);
        } else {
          store.channelsObject[payload.channel.channelId] = {
            ...store.channelsObject[payload.channel.channelId],
            ...payload.channel,
          };
          store.channels.push(payload.channel.channelId);
          store.archivedChannels = helpers.removeFromArray(
            store.archivedChannels,
            payload.channel.channelId,
          );
        }
      }

      store.channelsObject[payload.channel.channelId] = {
        ...currentValue,
        ...payload.channel,
      };

      if (payload.channelMeta) {
        store.channelsMeta[payload.channel.channelId] = {
          ...store.channelsMeta[payload.channel.channelId],
          ...payload.channelMeta,
        };
      }
    },
    handleChannelDelete: (store, { payload }: PayloadAction<{ channelId: number }>) => {
      const channel = store.channelsObject[payload.channelId];
      if (channel.isArchived) {
        store.archivedChannels = helpers.removeFromArray(store.archivedChannels, payload.channelId);
      } else {
        store.channels = helpers.removeFromArray(store.channels, payload.channelId);
      }
      store.channelsObject = _omit(store.channelsObject, payload.channelId);
    },
    handleChannelUsersUpdate: (store, { payload }: PayloadAction<{
      channel: IChannel;
      withMe: boolean;
    }>) => {
      const { channelId } = payload.channel;

      if (payload.withMe) {
        store.channelsObject[channelId].userToChannels = payload.channel.userToChannels;
        return;
      }

      store.channelsObject = _omit(store.channelsObject, channelId);
      if (payload.channel.isArchived) {
        store.archivedChannels = helpers.removeFromArray(store.archivedChannels, channelId);
        return;
      }
      store.channels = helpers.removeFromArray(store.channels, channelId);
    },
    handleNewMessage: (store, { payload }: PayloadAction<{
      channelId: number;
      message: IMessage;
    }>) => {
      const { channelId, message } = payload;
      store.channelDetailsObject[channelId].messages.push({
        messageId: message.messageId,
        threadMessages: [],
        threadMessagesObject: {},
      });

      store.channelDetailsObject[channelId].channelMessagesObject[message.messageId] = message;
    },

    handleNewSendingMessage: (store, { payload }: PayloadAction<{
      channelId: number;
      message: IMessage;
      userId: number;
    }>) => {
      const { channelId, message } = payload;
      store.channelDetailsObject[channelId].pendingMessages.errored = helpers.removeFromArray(
        store.channelDetailsObject[channelId].pendingMessages.errored,
        (i) => i.messageId === message.messageId,
      );
      store.channelDetailsObject[channelId].pendingMessages.sending.push(message);

      setPendingMessages(
        channelId,
        payload.userId,
        store.channelDetailsObject[channelId].pendingMessages,
      );
    },
    deleteErroredMessage: (store, { payload }: PayloadAction<{
      channelId: number;
      messageId: number;
      userId: number;
    }>) => {
      store.channelDetailsObject[payload.channelId].pendingMessages.errored = helpers.removeFromArray(
        store.channelDetailsObject[payload.channelId].pendingMessages.errored,
        (i) => i.messageId === payload.messageId,
      );

      setPendingMessages(
        payload.channelId,
        payload.userId,
        store.channelDetailsObject[payload.channelId].pendingMessages,
      );
    },
    deleteMessage: (store, { payload }: PayloadAction<{
      channelId: number;
      messageId: number;
    }>) => {
      const channelData = store.channelDetailsObject[payload.channelId];

      store.channelDetailsObject[payload.channelId].messages = helpers.removeFromArray(
        channelData.messages,
        (i) => i.messageId === payload.messageId,
      );

      const channelMessages = { ...channelData.channelMessagesObject };
      delete channelMessages[payload.messageId];
      store.channelDetailsObject[payload.channelId].channelMessagesObject = channelMessages;
    },
    updateMessage: (store, { payload }: PayloadAction<{
      channelId: number;
      messageId: number;
      messageData: Partial<IMessage>;
    }>) => {
      const oldMessage = store.channelDetailsObject[payload.channelId].channelMessagesObject[payload.messageId];
      store.channelDetailsObject[payload.channelId].channelMessagesObject[payload.messageId] = {
        ...oldMessage,
        ...payload.messageData,
      };
    },
    setThreadMessage: (store, { payload }: PayloadAction<{ channelId: number; messageId: number | null }>) => {
      if (!store.channelDetailsObject[payload.channelId]) {
        return console.warn('Channel not found (id "setThreadMessage" reducer)');
      }

      store.channelDetailsObject[payload.channelId].openedThreadMessageId = payload.messageId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(chatThunksV2.getMyChannels.fulfilled, (store, { payload }) => {
      store.channelsObject = payload.channelsObject;
      store.channels = payload.channels;
      store.dmChannels = payload.dmChannels;
      store.archivedChannels = payload.archivedChannels;
      store.channelsMeta = payload.channelsMeta;
      store.isChannelsLoading = false;
    });
    builder.addCase(chatThunksV2.getMyChannels.pending, (store) => {
      if (
        store.channels.length ||
        store.dmChannels.length ||
        store.archivedChannels.length
      ) {
        return;
      }
      store.isChannelsLoading = true;
    });
    builder.addCase(chatThunksV2.getMyChannels.rejected, (store, { error }) => {
      console.error('Failed to get my channels', error);

      store.getMyChannelsError = error;
      store.isChannelsLoading = false;
    });

    builder.addCase(chatThunksV2.markMessageAsRead.fulfilled, (store, { payload }) => {
      store.channelsMeta[payload.channelId] = payload.newChannelMeta;
      const userToChannelIndex = store.channelsObject[payload.channelId].userToChannels!
        .findIndex((userToChannel) => userToChannel.userId === payload.userId);

      store.channelsObject[payload.channelId].userToChannels![userToChannelIndex].lastViewedMessageTime = payload.lastViewedMessageTime; // eslint-disable-line max-len
    });

    builder.addCase(chatThunksV2.getChannelData.fulfilled, (store, { payload }) => {
      const {
        channel,
        pinnedMessages,
        messages,
        messagesObject,
        usersToChannel,
        users,
      } = payload;

      store.usersObject = {
        ...store.usersObject,
        ...users,
      };

      store.channelsObject[channel.channelId] = channel;

      const storedPendingMessages = getPendingMessages(channel.channelId, payload.userId);
      storedPendingMessages.errored = [
        ...storedPendingMessages.errored,
        ...storedPendingMessages.sending.map((message) => ({
          ...message,
          isSending: false,
          isError: true,
        })),
      ];
      storedPendingMessages.sending = [];
      const pendingMessages = storedPendingMessages;

      const oldData = store.channelDetailsObject[channel.channelId];
      store.channelDetailsObject[channel.channelId] = {
        ...oldData,
        channelId: channel.channelId,
        messages,
        pendingMessages,
        channelMessagesObject: messagesObject,
        pinnedMessages,
        usersToChannel,
        hasMoreUp: payload.hasMoreUp,
        isLoadingUp: false,
        hasMoreDown: payload.hasMoreDown,
        isLoadingDown: false,
        firstUnreadMessageId: payload.firstUnreadMessageId,
        openedThreadMessageId: oldData?.openedThreadMessageId ?? null,
      };
    });

    builder.addCase(chatThunksV2.getChannelData.rejected, (store, { error }) => {
      console.error('Failed to get channel data:', error);

      toast.error(t('errors:server.internal.unexpected'));
    });

    builder.addCase(chatThunksV2.sendMessage.fulfilled, (store, { payload }) => {
      const { channelId, message, temporaryMessageId } = payload;

      const channelDetails = store.channelDetailsObject[channelId];
      if (!channelDetails) {
        return;
      }

      const updatePendingMessages = () => {
        setPendingMessages(
          channelId,
          payload.userId,
          store.channelDetailsObject[channelId].pendingMessages,
        );
      };

      store.channelDetailsObject[channelId].pendingMessages.sending = helpers.removeFromArray(
        store.channelDetailsObject[channelId].pendingMessages.sending,
        (i) => i.messageId === temporaryMessageId,
      );

      if (payload.isFailed) {
        store.channelDetailsObject[channelId].pendingMessages.errored.push(message);
        updatePendingMessages();
        return;
      }

      if (!channelDetails.hasMoreDown) {
        store.channelDetailsObject[channelId].channelMessagesObject[message.messageId] = message;
        store.channelDetailsObject[channelId].messages.push({
          messageId: message.messageId,
          threadMessages: [],
          threadMessagesObject: {},
        });
      }
      updatePendingMessages();
    });

    builder.addCase(chatThunksV2.loadMoreMessages.pending, (store, { meta }) => {
      const key = meta.arg.anchorType === 'top' ? 'isLoadingUp' as const : 'isLoadingDown' as const;
      store.channelDetailsObject[meta.arg.channelId][key] = true;
    });
    builder.addCase(chatThunksV2.loadMoreMessages.fulfilled, (store, { payload, meta }) => {
      const key = meta.arg.anchorType === 'top' ? 'isLoadingUp' as const : 'isLoadingDown' as const;
      store.channelDetailsObject[meta.arg.channelId][key] = false;

      const channelDetails = store.channelDetailsObject[payload.channelId];

      const messages = [...channelDetails.messages];
      const channelMessagesObject = { ...channelDetails.channelMessagesObject };

      const newMessages = payload.messages.map((message) => {
        channelMessagesObject[message.messageId] = message;

        return {
          messageId: message.messageId,
          threadMessages: [],
          threadMessagesObject: {},
        };
      });

      if (payload.direction === 'top') {
        messages.unshift(...newMessages);
      } else {
        messages.push(...newMessages);
      }

      store.channelDetailsObject[payload.channelId] = {
        ...channelDetails,
        hasMoreUp: payload.hasMoreUp,
        hasMoreDown: payload.hasMoreDown,
        messages,
        channelMessagesObject,
      };
    });
    builder.addCase(chatThunksV2.loadMoreMessages.rejected, (store, { error, meta }) => {
      const key = meta.arg.anchorType === 'top' ? 'isLoadingUp' as const : 'isLoadingDown' as const;
      store.channelDetailsObject[meta.arg.channelId][key] = false;

      console.error('Failed to load more messages:', error);

      toast.error(t('errors:server.internal.unexpected'));
    });
  },
});

export const chatSliceV2Actions = chatSliceV2.actions;

export default chatSliceV2;
