import { createAsyncThunk } from '@reduxjs/toolkit';
import type { DefaultRootState } from 'react-redux';
import { CanceledError } from 'axios';

import chatWs from 'src/api/ws/chatWs';
import type { IChannel, IMessage, IMessageText, IUser, MessageType } from 'src/types';
import { chatSliceActions } from '../store/Chat.reducer';
import chatApi from 'src/api/chatApi';
import helpers from 'src/utils/helpers';
import type { AppStateType, AppThunkConfigType } from 'src/store';
// VVV Remove later VVV
import socket from 'src/api/ws/socket';
import socketConstants from 'src/api/socket/constants';
import { MessageTypeENUM } from 'src/types/chatTypes';
import { toast } from 'react-toastify';
import { t } from 'src/utils/getTranslation';
import chatSliceV2 from './chatSliceV2';
import chatWs_v2 from '../api/chatWs_v2';
import chatScrollController from '../utils/chatScrollController';
import { MESSAGES_LIST_LIMIT } from '../constants';
import messageApi_V2 from '../api/messageApi_V2';
import { getMentionsFromText } from './chatSliceUtils';

const getMyChannels = createAsyncThunk('chatPage-v2/getMyChannels', async () => {
  const { allChannels, channelsMeta } = await chatWs.getMyChannels();

  const channelsObject: Record<string, IChannel> = {};
  const channels: number[] = [];
  const dmChannels: number[] = [];
  const archivedChannels: number[] = [];

  allChannels.channels.forEach((channel) => {
    channelsObject[channel.channelId] = channel;
    channels.push(channel.channelId);
  });

  allChannels.dmChannels.forEach((channel) => {
    channelsObject[channel.channelId] = channel;
    dmChannels.push(channel.channelId);
  });

  allChannels.archivedChannels.forEach((channel) => {
    channelsObject[channel.channelId] = channel;
    archivedChannels.push(channel.channelId);
  });

  return {
    channelsObject,
    channels,
    dmChannels,
    archivedChannels,
    channelsMeta,
  };
});

const markMessageAsRead = createAsyncThunk('chatPage-v2/markMessageAsRead', async (
  data: { message: MessageType; channel: IChannel },
  { getState, dispatch },
) => {
  const store = getState() as DefaultRootState;

  const { channelId } = data.channel;
  const lastViewedMessageTime = data.message.createdAt as string;
  const newChannelMeta = await chatWs.readMessage({
    channelId,
    lastViewedMessageTime,
  });

  dispatch(chatSliceActions.setLastViewedMessageForUser({
    channelId,
    userId: store.main.user!.userId,
    lastViewed: lastViewedMessageTime,
  }));

  return {
    channelId,
    newChannelMeta: newChannelMeta.channelMeta,
    lastViewedMessageTime,
    userId: store.main.user!.userId,
  };
});

const getChannelData = createAsyncThunk('chatPage-v2/getChannelData', async (
  data: { channelId: number },
  { getState },
) => {
  const { chatPageV2: { channelsMeta }, main: { user } } = getState() as AppStateType;

  chatScrollController.setChannelId(data.channelId);

  const getChannelAndPinnedMessages = async () => {
    const response = await chatApi.getChannelWithPinned(data.channelId!);
    const channel = response.data.payload;

    const pinnedMessages = channel.messages as IMessage[];
    delete channel.messages;

    return { channel, pinnedMessages };
  };

  const getChannelMessages = async () => {
    const unreadMessagesCount = channelsMeta[data.channelId]?.unreadMessagesCount || 0;

    const response = await chatWs_v2.getChannelMesages({
      channelId: data.channelId,
      limit: MESSAGES_LIST_LIMIT,
      unreadMessagesCount,
    });

    if (response.meta.firstUnreadMessageId) {
      chatScrollController.scrollToFirstUnread();
    }

    return {
      channelMessages: response.payload,
      hasMoreUp: response.meta.hasMoreUp ?? false,
      hasMoreDown: response.meta.hasMoreDown ?? false,
      firstUnreadMessageId: response.meta.firstUnreadMessageId ?? null,
    };
  };

  const [
    { channel, pinnedMessages },
    { channelMessages, hasMoreUp, hasMoreDown, firstUnreadMessageId },
  ] = await Promise.all([
    getChannelAndPinnedMessages(),
    getChannelMessages(),
  ]);

  const messagesObject = {
    ...helpers.arrayToObject(pinnedMessages as IMessage[], 'messageId'),
    ...helpers.arrayToObject(channelMessages as IMessage[], 'messageId'),
  };

  const messages = channelMessages.map(({ messageId }) => ({
    messageId,
    threadMessages: [],
    threadMessagesObject: {},
  }));

  const users = {} as Record<string, IUser>;
  const usersToChannel = channel.userToChannels!.map((userToChannel) => {
    users[userToChannel.userId!] = userToChannel.user!;
    const formatted = { ...userToChannel };
    delete formatted.user;

    return formatted;
  });

  return {
    channel,
    pinnedMessages: pinnedMessages.map((i) => i.messageId),
    messages,
    messagesObject,
    usersToChannel,
    users,
    userId: user!.userId,
    hasMoreUp,
    hasMoreDown,
    firstUnreadMessageId,
  };
});

const loadMoreMessages = createAsyncThunk('chatPage-v2/loadMoreMessages', async (
  data: {
    channelId: number;
    anchorType: 'top' | 'bottom';
  },
  { getState },
) => {
  const { chatPageV2: { channelDetailsObject } } = getState() as AppStateType;

  const channelDetails = channelDetailsObject[data.channelId]!;
  const messages = channelDetails.messages;

  const anchorMessageId = data.anchorType === 'top' ? messages[0].messageId : messages.at(-1)!.messageId;
  const anchorMessage = channelDetails.channelMessagesObject[anchorMessageId];
  const anchorData = anchorMessage.createdAt;

  const response = await chatWs_v2.getChannelMesages({
    channelId: data.channelId,
    limit: MESSAGES_LIST_LIMIT,
    downAnchor: data.anchorType === 'bottom' ? anchorData : undefined,
    upAnchor: data.anchorType === 'top' ? anchorData : undefined,
  });

  return {
    channelId: data.channelId,
    messages: response.payload,
    hasMoreUp: data.anchorType === 'top' ? response.meta.hasMoreUp! : channelDetails.hasMoreUp,
    hasMoreDown: data.anchorType === 'bottom' ? response.meta.hasMoreDown! : channelDetails.hasMoreDown,
    direction: data.anchorType,
  };
});

const sendMessage = createAsyncThunk('chatPage-v2/sendMessage', async (
  data: {
    channelId: number;
    messageText: string;
    temporaryMessage?: IMessage;
    referenceMessageId?: number | null;
  },
  { getState, dispatch },
) => {
  if (data.referenceMessageId) {
    // eslint-disable-next-line no-alert
    alert('Not implemented yet');
    throw new Error('');
  }
  const { channelId } = data;
  const store = getState() as AppStateType;
  const userId = store.main.user!.userId;

  const nowDate = new Date();
  const temporaryMessage: IMessage = data.temporaryMessage
    ? {
      ...data.temporaryMessage,
      isSending: true,
      isError: false,
    }
    : {
      messageId: -Date.now(),
      messageText: [{
        text: data.messageText,
        createdAt: nowDate,
        updatedAt: nowDate,
        messageTextId: Date.now(),
      }],
      author: store.main.user!,
      authorId: store.main.user?.userId,
      createdAt: nowDate,
      updatedAt: nowDate,
      hasBeenRead: false,
      isAnswer: false,
      isEdited: false,
      isSending: true,
      isError: false,
      isPinned: false,
      isViewed: false,
      type: MessageTypeENUM.message,
      reactions: [],
      channelId,
    };

  try {
    const channelDetails = store.chatPageV2.channelDetailsObject[channelId];

    if (channelDetails && !channelDetails.hasMoreDown) {
      dispatch(chatSliceV2.actions.handleNewSendingMessage({
        channelId,
        message: temporaryMessage,
        userId,
      }));
    }

    const mentions = getMentionsFromText(data.messageText);
    const response = await socket.emit<{ message: IMessage }>(socketConstants.SEND_REGULAR_MESSAGE, {
      channelId,
      message: {
        messageText: `<p>${data.messageText}</p>`,
        messageMedia: [],
      },
      userMentionsIds: mentions.add,
      temporaryId: temporaryMessage.messageId,
    });

    return {
      message: response.payload.message,
      channelId,
      temporaryMessageId: temporaryMessage.messageId,
      isFailed: false,
      userId,
    };
  } catch (err) {
    console.error('Failed to send a message', err);

    return {
      message: {
        ...temporaryMessage,
        isError: true,
        isSending: false,
      },
      channelId,
      temporaryMessageId: temporaryMessage.messageId,
      isFailed: true,
      userId,
    };
  }
});

const deleteMessage = createAsyncThunk('chatPage-v2/deleteMessage', async (
  data: {
    channelId: number;
    messageId: number;
  },
  { dispatch },
) => {
  try {
    dispatch(chatSliceV2.actions.updateMessage({
      ...data,
      messageData: { isSending: true },
    }));

    await socket.emit(socketConstants.DELETE_MESSAGE, data);

    dispatch(chatSliceV2.actions.deleteMessage(data));
  } catch (err) {
    console.error('Failed to delete a message:', err);

    toast.error(t('errors:server.internal.unexpected'));

    dispatch(chatSliceV2.actions.updateMessage({
      ...data,
      messageData: { isSending: false },
    }));
  }
});

const toggleMessagePinStatus = createAsyncThunk('chatPage-v2/toggleMessagePinStatus', async (
  data: {
    isPinned: boolean;
    messageId: number;
    channelId: number;
  },
  { dispatch, signal },
) => {
  const oldPinnedStatus = !data.isPinned;
  try {
    dispatch(chatSliceV2.actions.updateMessage({
      ...data,
      messageData: { isPinned: data.isPinned },
    }));

    await chatApi.togglePinMessage(
      data.messageId,
      data.channelId,
      signal,
    );
  } catch (err) {
    if (err instanceof CanceledError) {
      return;
    }

    console.error('Failed to toggle message pin status:', err);

    toast.error(t('errors:server.internal.unexpected'));

    dispatch(chatSliceV2.actions.updateMessage({
      ...data,
      messageData: { isPinned: oldPinnedStatus },
    }));
  }
});

const editMessage = createAsyncThunk<
  {
    channelId: number;
    message: IMessage;
  },
  {
    messageId: number;
    channelId: number;
    text: string;
  },
  AppThunkConfigType
>('chatPage-v2/editMessage', async (
  data,
  { getState, dispatch },
) => {
  const oldMessage = structuredClone(getState().chatPageV2.channelDetailsObject[data.channelId].channelMessagesObject[data.messageId]);

  try {
    const newMessageText: IMessageText[] = [];
    if (oldMessage.messageText?.length) {
      newMessageText[0] = {
        ...oldMessage.messageText[0],
        text: data.text,
      };
    }
    dispatch(chatSliceV2.actions.updateMessage({
      ...data,
      messageData: {
        ...oldMessage,
        messageText: newMessageText,
      },
    }));

    const oldText = oldMessage.messageText?.[0].text;
    const mentions = getMentionsFromText(data.text, oldText);
    await messageApi_V2.edit(data.messageId, {
      messageText: data.text,
      addUserMentionsIds: mentions.add,
      removeUserMentionsIds: mentions.remove,
    });

    return {
      channelId: data.channelId,
      message: oldMessage,
    };
  } catch (err) {
    console.error('Failed to update a message:', err);
    toast.error('Не удалось обновить сообщение');

    dispatch(chatSliceV2.actions.updateMessage({
      ...data,
      messageData: oldMessage,
    }));

    throw err;
  }
});

export default {
  getMyChannels,
  markMessageAsRead,
  getChannelData,
  loadMoreMessages,
  sendMessage,
  deleteMessage,
  toggleMessagePinStatus,
  editMessage,
};
