import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import { isDev } from 'src/config';
import rootReducer, { RESET_STORE_ACTION_TYPE } from './rootReducer';
import { jobPositionsQuery } from '../api/rtkQuery/jobPositionsQuery';
import { departmentsQuery } from '../api/rtkQuery/departmentsQuery';
import { usersQuery } from 'src/api/rtkQuery/usersQuery';
import storeChangeEvent from './storeChangeEvent';

const store = configureStore({
  reducer: rootReducer,
  devTools: isDev,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({ thunk: true, serializableCheck: false });

    if (isDev) {
      const logger = createLogger({ collapsed: true, duration: true });
      middlewares.push(logger);
    }

    middlewares.push(
      jobPositionsQuery.middleware,
      departmentsQuery.middleware,
      usersQuery.middleware,
    );

    return middlewares;
  },
  preloadedState: rootReducer(undefined, { type: RESET_STORE_ACTION_TYPE, payload: undefined }),
});

export type AppDispatchType = typeof store.dispatch;
export type AppStateType = ReturnType<typeof store.getState>;
export type AppThunkConfigType = {
  state: AppStateType;
  dispatch: AppDispatchType;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

declare module 'react-redux' {
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/naming-convention
  export interface DefaultRootState extends AppStateType { }
}

export const useAppDispatch: typeof useDispatch<AppDispatchType> = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppStateType> = useSelector;

export const resetStore = () => store.dispatch({ type: RESET_STORE_ACTION_TYPE });

store.subscribe(() => {
  const state = store.getState();
  storeChangeEvent.dispatch(state);
});

export default store;
