import http from 'src/api/http';

import type { ResponseType } from 'src/api/http';
import type { FindAndCountMetaType, IUser, IUserChannel } from 'src/types';
import type { ChannelType, IChannel, IChannelDelta, IMessage } from 'src/types/chatTypes';
import type { GetParamsType } from './http/http.types';

type CreateChannelType = {
  name: string;
  description?: string;
  icon?: string;
  isPrivate?: boolean;
};

type EditChannelType = {
  name?: string;
  description?: string;
  isPrivate?: boolean;
  icon?: string;
};

type EditMessageType = {
  messageText: string;
  addUserMentionsIds?: number[];
  removeUserMentionsIds?: number[];
};

type AddUserToChannelType = {
  userIds: number[];
};

type ReassignOwnerToChannelType = {
  userId: number;
};

type HasMoreMetaType = {
  hasMoreUp: boolean;
  hasMoreDown: boolean;
};

type GetUserChannelMetaType = {
  unreadMessageCount: {
    channelId: number;
    unreadCount: number;
  }[];
  mentionsCount: {
    channelId: number;
    mentions: number;
  }[];
};

export type GetMessagesParamsType = {
  channelId: number;
  sideMessagesCount: number;
  byMessageId?: number;
  byFirstUnreadMessage?: boolean;
};

const channelPath = 'channels';
const searchPath = 'search';
const messagesPath = 'messages';

const getChannelWithPinned = (id: number) => {
  return http.get<ResponseType<IChannel>>(`${channelPath}/with-pinned/${id}`);
};

const getArchivalChannel = () => {
  return http.get<ResponseType<IChannel[]>>(`${channelPath}/archival`);
};

const getUserChannels = () => {
  return http.get<ResponseType<IUserChannel, GetUserChannelMetaType>>(`${channelPath}/users-channels`);
};

const createChannel = (data: CreateChannelType) => {
  return http.post<ResponseType<ChannelType>>(`${channelPath}/create`, data);
};

const editChannel = (id: number, data: EditChannelType) => {
  return http.patch<ResponseType<ChannelType>>(`${channelPath}/edit/${id}`, data);
};

const leaveChannel = (id: number) => {
  return http.post(`${channelPath}/leave/${id}`);
};

const changeChannelArchiveStatus = (id: number) => {
  return http.patch(`${channelPath}/${id}/toggle-archive-status`);
};

const changeAllChannelArchiveStatus = () => {
  return http.patch(`${channelPath}/cancel-archive-all`);
};

const removeAllArchiveChannel = () => {
  return http.delete(`${channelPath}/remove-all-archived`);
};

const deleteChannel = (id: number) => {
  return http.delete(`${channelPath}/delete/${id}`);
};

const addUsersToChannel = (id: number, data: AddUserToChannelType) => {
  return http.post<ResponseType<IChannel>>(`${channelPath}/add-users/${id}`, data);
};

const joinToChannel = (id: number) => {
  return http.post<ResponseType<IChannel>>(`${channelPath}/join/${id}`);
};

const reassignOwnerToChannel = (id: number, data: ReassignOwnerToChannelType) => {
  return http.patch<ResponseType<IChannelDelta>>(`${channelPath}/reassign-owner/${id}`, data);
};

const removeUsersFromChannel = (id: number, data: AddUserToChannelType) => {
  return http.delete<ResponseType<IChannel>>(`${channelPath}/remove-user/${id}`, { data });
};

const findUsersToAddInChannel = (id: number, data: { keyword: string }) => {
  return http.post<ResponseType<IUser[]>>(`${channelPath}/find-users-to-add/${id}`, data);
};

const getUserSearchResults = (args: GetParamsType) => {
  const params = {
    page: args.page,
    limit: args.perPage,
    q: args.search,
  };
  return http.get<ResponseType<IUser[], FindAndCountMetaType>>(`${searchPath}/users`, { params });
};

const getChannelSearchResults = (args: GetParamsType & { withPagination?: boolean; showMyChannels?: boolean }) => {
  const params = {
    page: args.page,
    limit: args.perPage,
    q: args.search,
    withPagination: args.withPagination,
    showMyChannels: args.showMyChannels,
  };
  return http.get<ResponseType<IChannel[], FindAndCountMetaType>>(`${searchPath}/channels`, { params });
};

const getMessageSearchResults = (args: GetParamsType) => {
  const params = {
    page: args.page,
    limit: args.perPage,
    q: args.search,
  };
  return http.get<ResponseType<IMessage[], FindAndCountMetaType>>(`${searchPath}/${messagesPath}`, { params });
};

const getMessages = (
  params: GetMessagesParamsType,
) => {
  return http.get<ResponseType<IMessage[], HasMoreMetaType>>(`${messagesPath}/redirect-to/`, { params });
};

const editMessage = (messageId: number, data: EditMessageType) => {
  return http.patch<ResponseType<IMessage>>(`${messagesPath}/${messageId}`, data);
};

const togglePinMessage = (messageId: number, channelId: number, signal?: AbortSignal) => {
  return http.post<ResponseType<IMessage>>(`${messagesPath}/pin-message/${messageId}`, { channelId }, { signal });
};

const readMessage = (data: { messageId: number; channelId: number }) => {
  return http.patch<ResponseType<{
    lastViewedMessageTime: string | Date;
    userId: number;
  }>>(`${channelPath}/read-message`, data);
};

export default {
  getUserChannels,
  createChannel,
  editChannel,
  changeChannelArchiveStatus,
  changeAllChannelArchiveStatus,
  removeAllArchiveChannel,
  deleteChannel,
  addUsersToChannel,
  reassignOwnerToChannel,
  removeUsersFromChannel,
  findUsersToAddInChannel,
  getUserSearchResults,
  getChannelSearchResults,
  getMessageSearchResults,
  getArchivalChannel,
  getChannelWithPinned,
  leaveChannel,
  getMessages,
  editMessage,
  togglePinMessage,
  readMessage,
  joinToChannel,
};
